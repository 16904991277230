<template>
  <div class="container">
    <div class="main-content">
      <div class="menu">
        <div class="menu-title">
          <p>资料下载</p>
        </div>
      </div>
      <div class="right-content">
        <div class="content-list">
          <ul>
            <li class="item" v-for="(item,index) in downarr" :key="index">
              <div class="title">
                {{item.title}}
              </div>
              <div class="time">
                <a :href="item.url" :download="item.url">下载资料</a>
              </div>
            </li>
          </ul>
        </div>
        <div class="page">
          <el-pagination background layout="prev, pager, next" :total="10">
          </el-pagination>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  data () {
    return {
      downarr: [
        {
          title: "GMT 0005-2012随机性检测规范",
          url: "http://www.dplslab-jx.com/filedown/GMT 0005-2012随机性检测规范.pdf"
        },
        {
          title: "GMT 0022-2014IPSec VPN 技术规范",
          url: "http://www.dplslab-jx.com/filedown/GMT 0022-2014IPSec VPN 技术规范.pdf"
        },
        {
          title: "GMT 0024-2014SSL VPN 技术规范",
          url: "http://www.dplslab-jx.com/filedown/GMT 0024-2014SSL VPN 技术规范.pdf"
        },
        {
          title: "GMT 0091-2020基于口令的密钥派生规范",
          url: "http://www.dplslab-jx.com/filedown/GMT 0091-2020基于口令的密钥派生规范.pdf"
        },
        {
          title: "GMT 0092-2020基于SM2算法的证书申请语法规范",
          url: "http://www.dplslab-jx.com/filedown/GMT 0092-2020基于SM2算法的证书申请语法规范.pdf"
        },
        {
          title: "GMT 0093-2020证书与密钥交换格式规范",
          url: "http://www.dplslab-jx.com/filedown/GMT 0093-2020证书与密钥交换格式规范.pdf"
        },
        {
          title: "GMT 0094-2020公钥密码应用技术体系框架规范",
          url: "http://www.dplslab-jx.com/filedown/GMT 0094-2020公钥密码应用技术体系框架规范.pdf"
        },
        {
          title: "GMT 0095-2020电子招投标密码应用技术要求",
          url: "http://www.dplslab-jx.com/filedown/GMT 0095-2020电子招投标密码应用技术要求.pdf"
        },
        {
          title: "GMT 0096-2020射频识别防伪系统密码应用指南",
          url: "http://www.dplslab-jx.com/filedown/GMT 0096-2020射频识别防伪系统密码应用指南.pdf"
        },
        {
          title: "GMT 0097-2020射频识别电子标签统一名称解析服务安全技术规范",
          url: "http://www.dplslab-jx.com/filedown/GMT 0097-2020射频识别电子标签统一名称解析服务安全技术规范.pdf"
        },
        {
          title: "GMT 0098-2020基于IP网络的加密语音通信密码技术规范",
          url: "http://www.dplslab-jx.com/filedown/GMT 0098-2020基于IP网络的加密语音通信密码技术规范.pdf"
        },
        {
          title: "GMT 0099-2020开放式版式文档密码应用技术规范",
          url: "http://www.dplslab-jx.com/filedown/GMT 0099-2020开放式版式文档密码应用技术规范.pdf"
        },
        {
          title: "GMT 0100-2020人工确权型数字签名密码应用技术要求",
          url: "http://www.dplslab-jx.com/filedown/GMT 0100-2020人工确权型数字签名密码应用技术要求.pdf"
        },
        {
          title: "GMT 0101-2020近场通信密码安全协议检测规范",
          url: "http://www.dplslab-jx.com/filedown/GMT 0101-2020近场通信密码安全协议检测规范.pdf"
        },
        {
          title: "GMT 0102-2020密码设备应用接口符合性检测规范",
          url: "http://www.dplslab-jx.com/filedown/GMT 0102-2020密码设备应用接口符合性检测规范.pdf"
        },
        {
          title: "GMY 5001-2017密码标准应用指南",
          url: "http://www.dplslab-jx.com/filedown/GMY 5001-2017密码标准应用指南.pdf"
        },
        {
          title: "GMY 5002-2018云计算身份鉴别服务密码标准体系",
          url: "http://www.dplslab-jx.com/filedown/GMY 5002-2018云计算身份鉴别服务密码标准体系.pdf"
        },
        {
          title: "GMZ 4001-2013密码术语",
          url: "http://www.dplslab-jx.com/filedown/GMZ 4001-2013密码术语.pdf"
        }
      ]
    }
  },
  components: {

  }
}
</script>

<style scoped lang="scss">
.container {
  width: 100%;
  .main-content {
    width: 1200px;
    margin: 60px auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    .menu {
      // position: absolute;
      float: left;
      width: 320px;
      height: 200px;
      background: #fff;
      border-bottom: 1px solid #eeeeee;
      line-height: 35px;
      .menu-title {
        background: #409eff;
        padding: 30px 10px 34px 40px;
        text-align: left;
        p {
          font-size: 24px;
          color: #fff;
          margin: 0;
          padding: 0;
        }
      }
    }

    .right-content {
      float: left;
      width: 830px;
      background: #ffffff;
      padding: 0 14px;
      position: relative;
      padding-top: 20px;
      .page {
        padding: 20px;
      }
      .content-list {
        // padding: 20px 10px;
        .item {
          //box-sizing: border-box;
          /* width: 60%; */
          //width: 100%;
          /* padding-bottom: 18px; */
          overflow: hidden;
          // position: relative;
          .title {
            font-size: 20px;
            color: #333;
            line-height: 50px;
            height: 50px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            overflow: hidden;
            width: calc(100% - 200px);
            float: left;
            padding-left: 30px;
            position: relative;
            text-align: left;
          }

          .title:before {
            content: '';
            position: absolute;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            overflow: hidden;
            left: 0;
            top: 22px;
            background: #b9b9b9;
            transition: all 0.3s ease;
          }

          .time {
            line-height: 50px;
            float: right;
            a {
              padding: 5px 10px;
              color: #666;
              border-radius: 20px;
              border: #666 1px solid;
            }

            a:hover {
              color: #409eff;
              border: #409eff 1px solid;
            }
          }
        }
      }
    }
  }
}
</style>
